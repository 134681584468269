<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Formulir Penebangan </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPenebangan">
            <CRow>
              <CCol sm="6">             
                <div class="form-group">
                <label>Jenis Pengajuan</label>
                  <select class="form-control" v-model="jenis">
                    <option value="" disabled selected>Pilih Jenis</option>
                    <option value="penebangan">Penebangan</option>
                    <option value="pemangkasan">Pemangkasan</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_pemohon"
                  label="Nama Pemohon"
                  placeholder="Input Nama Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="alamat_pemohon"
                  label="Alamat Pemohon"
                  placeholder="Input Alamat Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="email_pemohon"
                  label="Email Pemohon"
                  placeholder="Input Email Pemohon"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  type="text"
                  v-model="tlp_pemohon"
                  label="No Telepon Pemohon"
                  placeholder="Input No Telepon Pemohon"
                />
              </CCol>
              
              <CCol sm="12">
                <CInput
                  v-model="alamat_pohon"
                  label="Alamat Pohon"
                  placeholder="Input Alamat Pohon"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="keterangan"
                  label="Keterangan"
                  placeholder="Input Keterangan"
                />  
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Pohon</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol> 
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Foto KTP</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon02">
                    <label class="custom-file-label" for="inputGroupFile02">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Sekitar Pohon</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange3($event)" id="inputGroupFile03"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon03">
                    <label class="custom-file-label" for="inputGroupFile03">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>  -->

            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/penebangan">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      jenis : "",      
      nama_pemohon : "",      
      alamat_pemohon : "",
      email_pemohon : "",
      tlp_pemohon : "",
      alamat_pohon : "",
      keterangan : "",
      
      foto_ktp : "",
      foto_ktp_text : "",
      foto_pohon : "",
      foto_pohon_text : "",
      foto_sekitar : "",
      foto_sekitar_text : "",

      placeholder : "Pilih File",
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
    }
  },
  mounted(){
  // this.loadData();
  },

  methods: {

    // loadData: function(event){

    //   axios.get(process.env.VUE_APP_BASE_URL+"rubriks")
    //     .then((response) => {
    //           this.rubriks=response.data;
    //     });
    // },
    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.foto_pohon_text = fileData.name;
      this.foto_pohon = fileData;
      this.placeholder = fileData.name;
    },
    // onFileChange2(event) {
    //   var fileData = event.target.files[0];
    //   this.foto_ktp_text = fileData.name;
    //   this.foto_ktp = fileData;
    //   this.placeholder = fileData.name;
    // },
    // onFileChange3(event) {
    //   var fileData = event.target.files[0];
    //   this.foto_sekitar_text = fileData.name;
    //   this.foto_sekitar = fileData;
    //   this.placeholder = fileData.name;
    // },

    inputPenebangan: function(event){
     
      const formData = new FormData();

      formData.append('jenis_pengajuan', this.jenis)
      formData.append('nama_pemohon', this.nama_pemohon)
      formData.append('alamat_pemohon', this.alamat_pemohon)
      formData.append('email_pemohon', this.email_pemohon)
      formData.append('tlp_pemohon', this.tlp_pemohon)
      formData.append('alamat_pohon', this.alamat_pohon)
      formData.append('keterangan', this.keterangan)
      
      formData.append('foto_pohon', this.foto_pohon, this.foto_pohon.name) 
      formData.append('foto_pohon_text', this.foto_pohon.name)
      // formData.append('foto_ktp', this.foto_ktp, this.foto_ktp.name) 
      // formData.append('foto_ktp_text', this.foto_ktp.name)
      // formData.append('foto_sekitar', this.foto_sekitar, this.foto_sekitar.name) 
      // formData.append('foto_sekitar_text', this.foto_sekitar.name)
      
      axios.post(process.env.VUE_APP_BASE_URL+"penebangan", formData )
        .then((response) => {
            if(response.data.status==='success'){
              this.$router.push('/penebangan');
            }
            else{
              this.modalError=true;
            }           
        })
    }
  }
}
</script>